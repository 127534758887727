<template>
  <v-text-field
    outlined
    dense
    class="baseTextField"
    single-line
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot
      slot="append-outer"
      name="append-outer"
    />
  </v-text-field>
</template>

<script>
  export default {
    name: 'BaseTextField',
  }
</script>

<style>
.baseTextField {
  max-width: 730px;
}
</style>
