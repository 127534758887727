<template>
  <v-theme-provider :dark="dark">
    <v-container class="pa-0">
      <v-row :justify="justify" no-gutters>
        <v-col v-if="icon" :class="`text-${align}`" cols="12" class="mb-4">
          <base-icon :color="color">
            {{ icon }}
          </base-icon>
        </v-col>

        <v-col v-if="titleCode || subtitle" :cols="callout ? 9 : 12">
          <base-subtitle :color="color" :style="textColor ? 'color: ' + textColor : ''" v-if="subtitle" :title="subtitle" space="1" />

          <base-title :color="color" :style="textColor ? 'color: ' + textColor : ''" :title='$t(titleCode)' class="text-uppercase" space="1" />

          <base-body :color="color" :style="textColor ? 'color: ' + textColor : ''" v-if="textCode || $slots.default" :text='$t(textCode)' space="0">
            <slot :color="color" :style="textColor ? 'color: ' + textColor : ''"  />
          </base-body>
        </v-col>

        <v-col v-if="callout" cols="2">
          <div
            class="display-3 grey--text text--lighten-4 font-weight-bold pr-4"
            v-text="callout"
            :style="textColor ? 'color: ' + textColor : ''" 
          />
        </v-col>
      </v-row>
    </v-container>
  </v-theme-provider>
</template>

<script>
// Mixins
import Heading from "@/mixins/heading";

export default {
  name: "BaseInfoCard",

  mixins: [Heading],

  props: {
    dark: Boolean,
    callout: String,
    color: {
      type: String,
      default: "primary"
    },
    textColor: String,
    icon: String,
    subtitle: String,
    textCode: String,
    titleCode: String,
    text: String,
    title: String
  }
};
</script>
