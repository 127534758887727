// Imports
import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      component: () => import("@/layouts/home/Index.vue"),
      children: [
        {
          path: "",
          name: "Home",
          component: () => import("@/views/home/Index.vue")
        },
        {
          path: "contact-us",
          name: "Contact",
          component: () => import("@/views/contact-us/Index.vue")
        },
        {
          path: "kontakt",
          name: "Kontakt",
          component: () => import("@/views/contact-us/Index.vue")
        },
        {
          path: "sign-up",
          name: "Sign up",
          component: () => import("@/views/sign-up/Index.vue")
        },
        {
          path: "registreeri",
          name: "Registreeri",
          component: () => import("@/views/sign-up/Index.vue")
        },
        {
          path: "pricing",
          name: "Pricing",
          component: () => import("@/views/pricing/Index.vue")
        },
        {
          path: "hinnad",
          name: "Hinnad",
          component: () => import("@/views/pricing/Index.vue")
        },
        {
          path: "products",
          name: "Products",
          component: () => import("@/views/products/Index.vue")
        },
        {
          path: "tooted",
          name: "Tooted",
          component: () => import("@/views/products/Index.vue")
        },
        {
          path: "faq",
          name: "faq",
          component: () => import("@/views/faq/Index.vue")
        },
        {
          path: "korduma-kippuvad-kusimused",
          name: "KKK",
          component: () => import("@/views/faq/Index.vue")
        },
        {
          path: "benefits",
          name: "Benefits",
          component: () => import("@/views/benefits/Index.vue")
        },
        {
          path: "eelised",
          name: "Eelised",
          component: () => import("@/views/benefits/Index.vue")
        },
        {
          path: "features",
          name: "Features",
          component: () => import("@/views/features/Index.vue")
        },
        {
          path: "funktsioonid",
          name: "Funktsioonid",
          component: () => import("@/views/features/Index.vue")
        },
        {
          path: "privacy-policy",
          name: "Privacy policy",
          component: () => import("@/views/privacy-policy/Index.vue")
        },
        {
          path: "privaatsuspoliitika",
          name: "Privaatsuspoliitika",
          component: () => import("@/views/privacy-policy/Index.vue")
        },
        {
          path: "terms-of-service",
          name: "Terms of service",
          component: () => import("@/views/terms-of-service/Index.vue")
        },
        {
          path: "teenuse-tingimused",
          name: "Teenuse tingimused",
          component: () => import("@/views/terms-of-service/Index.vue")
        },
        {
          path: "data-protection-agreement",
          name: "Data protection agreement",
          component: () => import("@/views/data-protection-agreement/Index.vue")
        },
        {
          path: "andmekaitseleping",
          name: "Andmekaitseleping",
          component: () => import("@/views/data-protection-agreement/Index.vue")
        },
        {
          path: "security",
          name: "Security",
          component: () => import("@/views/security/Index.vue")
        },
        {
          path: "turvalisus",
          name: "Turvalisus",
          component: () => import("@/views/security/Index.vue")
        },
        {
          path: "sub-processors",
          name: "Sub-processors",
          component: () => import("@/views/sub-processors/Index.vue")
        },
        {
          path: "volitatud-tootlejad",
          name: "Volitatud töötlejad",
          component: () => import("@/views/sub-processors/Index.vue")
        },
        {
          path: "*",
          name: "FourOhFour",
          component: () => import("@/views/404/Index.vue")
        }
      ]
    }
  ]
});

export default router;
