<template>
  <v-textarea
    outlined
    dense
    single-line
    class="baseTextarea"
    v-bind="{
      rows: 6,
      ...$attrs,
    }"
    v-on="$listeners"
  />
</template>

<script>
  export default {
    name: 'BaseTextarea',
  }
</script>

<style>
.baseTextarea {
  max-width: 730px;
}
</style>
