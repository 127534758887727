<template>
  <v-fade-transition mode="out-in">
    <router-view />
  </v-fade-transition>
</template>

<script>
import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

export default {
  name: "App",
  metaInfo: {
    titleTemplate: "Tentifly - Smart sales | booking assistant",
    htmlAttrs: { lang: "en" },
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" }
    ]
  }
};
</script>
<style>
.zoom {
  transition: transform 0.2s;
}

@media (hover: hover) and (min-width: 960px) {
  .zoom:hover {
    transform: scale(1.4);
    z-index: 999;
  }
}
</style>

<style lang="sass">
.extra-padding
  padding-bottom: 96px !important
  padding-top: 96px !important

  @media screen and (max-width: 959px)
    padding-top: 48px !important
    padding-bottom: 48px !important
</style>
